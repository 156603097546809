@import '_fonts';
@import '_variables';


html {
  scroll-behavior: smooth;
}

body > iframe {
  pointer-events: none;
}

.activeConversation {
  border-color: #1E1A34;
  box-shadow: 0 0 0 1px #1E1A34 inset;
}

.messageFrom .MuiAvatar-circular {
  background-color: #1E1A34;
}

.messageTo .MuiAvatar-circular {
  background-color: #6941C6;
}